<template>
  <div class="onboarding-page__wrapper">
    <div class="profile-page">
      <h2>크리에이터님에 대해 알려주세요.</h2>
      <p class="sub-text-s2 text-gray-second">
        크리에이터님에 대한 소개와 빅크에서 어떤 콘텐츠를 판매할 예정인지 활동
        계획을 알려주세요.
      </p>

      <div>
        <input-basic
          placeholder="활동명 혹은 채널명을 입력해주세요"
          label="크리에이터명"
          :default-value="state.creatorName"
          :max-length="20"
          :caption="`${state.creatorName.length}/20`"
          @updateData="(value) => actions.updateCreatorName(value)"
        ></input-basic>

        <input-text-box
          v-model="state.introduction"
          label="간단한 소개 및 활동 계획"
          placeholder="크리에이터님에 대한 소개와 활동 계획을 입력해주세요"
          :max-length="500"
          :rows="4"
          :caption="`(${state.introduction.length}/500)`"
          :height-resize="false"
          :border-none="false"
        ></input-text-box>
      </div>

      <button-basic
        class="save-btn"
        text="다음"
        :disabled="state.saveBtnDisabled"
        @action="actions.goToInterest()"
      ></button-basic>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeMount, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import InputBasic from "../../../../components/console/inputs/InputBasic";
import InputTextBox from "../../../../components/console/inputs/InputTextBox";
import ButtonBasic from "../../../../components/console/buttons/ButtonBasic";

export default {
  name: "OnBoardingProfile",
  components: { ButtonBasic, InputTextBox, InputBasic },
  setup() {
    const store = useStore();
    const router = useRouter();

    const state = reactive({
      user: computed(() => {
        return store.getters["auth/user"];
      }),
      creatorName: "",
      introduction: "",
      saveBtnDisabled: computed(() => {
        return !state.creatorName || !state.introduction;
      }),
    });

    onBeforeMount(() => {
      state.creatorName = state.user.userProfile.displayName ?? "";
      state.introduction = state.user.userProfile.introduction ?? "";
    });

    const actions = {
      goToInterest: () => {
        // note 온보딩 프로세스중 데이터를 저장하는 부분은 step을 해당 API 에서 업데이트 함.
        store
          .dispatch("onBoardings/postOnBoardingProfile", {
            data: {
              displayName: state.creatorName,
              introduction: state.introduction,
            },
          })
          .then(() => {
            router.push({ name: "console.onboarding.interest" });
          });
      },
      updateCreatorName: (value) => {
        state.creatorName = value;
      },
    };

    return { state, actions };
  },
};
</script>

<style src="./style.css" scoped></style>
